import Immutable from "immutable";
import moment from "moment";

import { USER_LOGOUT } from "../types/auth";
import {
  ADD_FILES,
  CHANGE_NAME_EDITABLE,
  CLEAR_CONVERSATION,
  CLEAR_FILES,
  CNG_SEARCH_INTERNET,
  CREATE_CONVERSATION_DONE,
  DEL_CONVERSATION_DONE,
  DISLIKE_CONVERSATION_DONE,
  FCH_CONVERSATION_DONE,
  FCH_CONVERSATIONS_DONE,
  FCH_LOGS_DONE,
  GET_REFERENCE_DONE,
  LIKE_CONVERSATION_DONE,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGE_DONE,
  RECEIVE_STREAM_MESSAGE_DONE,
  REMOVE_FILE,
  RESET_CONVERSATION,
  RESET_CONVERSATION_DONE,
  SEARCH_SOURCES,
  SEL_SOURCES,
  SEND_MESSAGE,
  SEND_MESSAGE_DONE,
  SEND_STREAM_MESSAGE,
  SEND_STREAM_MESSAGE_DONE,
  SET_LOG_INDEX,
  STOP_BATCH_MESSAGE,
  STOP_STREAM_MESSAGE,
  UPD_NAME_DONE,
} from "../types/conversation";

const INIT_STATE = Immutable.OrderedMap({
  isLoading: false,
  isSending: false,
  isWaiting: false,
  sources: [],
  files: [],
  is_search_internet: false,
  config: {
    isEditable: false,
    logIndex: 0,
    selectedSources: Immutable.List(),
  },
  results: {
    logs: null,
    conversations: [],
    info: null,
    reference: null,
  },
});

const conversationReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_CONVERSATIONS_DONE: {
      const data = Immutable.fromJS(payload.doc).sort(
        (a, b) => b.get("created_at") - a.get("created_at"),
      );
      return _.setIn(["results", "conversations"], data);
    }
    case FCH_CONVERSATION_DONE: {
      const data = Immutable.fromJS(payload.doc);
      return _.setIn(["results", "info"], data).set(
        "sources",
        data.get("sources"),
      );
    }
    case FCH_LOGS_DONE: {
      const data = Immutable.fromJS(payload.doc);
      const lastAssistantIndex = data.findLastIndex(
        (log) => log.get("role") === "assistant",
      );
      return _.setIn(["results", "logs"], data).setIn(
        ["config", "logIndex"],
        lastAssistantIndex,
      );
    }
    case CREATE_CONVERSATION_DONE: {
      const data = _.getIn(["results", "conversations"]);
      const newData = data.unshift(Immutable.fromJS(payload.doc));
      return _.setIn(["results", "conversations"], newData);
    }
    case SEND_MESSAGE:
    case SEND_STREAM_MESSAGE: {
      return _.set("isSending", true).set("isWaiting", true);
    }
    case SEND_MESSAGE_DONE:
    case SEND_STREAM_MESSAGE_DONE: {
      const data = _.getIn(["results", "logs"]);
      const newData = data.concat(Immutable.fromJS(payload.doc));
      return _.setIn(["results", "logs"], newData)
        .set("isSending", false)
        .set("files", []);
    }
    case RECEIVE_MESSAGE: {
      const data = _.getIn(["results", "logs"]);
      const lastAssistantIndex = data.findLastIndex(
        (log) => log.get("id") === payload.doc.id,
      );
      if (lastAssistantIndex !== -1) {
        const existingMessage = data.getIn([lastAssistantIndex, "message"]);
        const updatedMessage = existingMessage + payload.doc.message;

        let updatedData = data.setIn(
          [lastAssistantIndex, "message"],
          updatedMessage,
        );

        if (payload.doc.chartjs) {
          updatedData = updatedData.setIn(
            [lastAssistantIndex, "chartjs"],
            Immutable.fromJS(payload.doc.chartjs),
          );
        }
        if (payload.doc.sql) {
          updatedData = updatedData.setIn(
            [lastAssistantIndex, "sql"],
            Immutable.fromJS(payload.doc.sql),
          );
        }

        return _.setIn(["results", "logs"], updatedData).setIn(
          ["config", "logIndex"],
          lastAssistantIndex,
        );
      } else {
        const newData = data.push(Immutable.fromJS(payload.doc));
        return _.setIn(["results", "logs"], newData);
      }
    }
    case RECEIVE_MESSAGE_DONE: {
      const data = _.getIn(["results", "logs"]);

      const lastAssistantIndex = data.findLastIndex(
        (log) => log.get("id") === payload.id,
      );

      const updatedData = data.setIn(
        [lastAssistantIndex, "created_at"],
        moment().unix(),
      );
      return _.setIn(["results", "logs"], updatedData).set("isWaiting", false);
    }
    case RECEIVE_STREAM_MESSAGE_DONE: {
      const data = _.getIn(["results", "logs"]);

      const lastAssistantIndex = data.findLastIndex(
        (log) => log.get("id") === payload.id,
      );

      const updatedData = data
        .setIn([lastAssistantIndex, "created_at"], moment().unix())
        .setIn([lastAssistantIndex, "id"], payload.updatedId);
      return _.setIn(["results", "logs"], updatedData).set("isWaiting", false);
    }
    case STOP_STREAM_MESSAGE: {
      return _.set("isWaiting", false).set("isSending", false);
    }
    case STOP_BATCH_MESSAGE: {
      let _newLogs = _.getIn(["results", "logs"]);

      _newLogs = _newLogs.pop();

      return _.set("isSending", false)
        .set("isWaiting", false)
        .setIn(["results", "logs"], _newLogs);
    }
    case DEL_CONVERSATION_DONE: {
      const data = _.getIn(["results", "conversations"]);
      const newData = data.filter((d) => d.get("id") !== payload.id);
      return _.setIn(["results", "conversations"], newData);
    }
    case UPD_NAME_DONE: {
      const _newList = _.getIn(["results", "conversations"]).map((item) => {
        if (item.get("id") === payload.id) {
          return item.set("name", payload.name);
        }
        return item;
      });
      const info = _.getIn(["results", "info"]);
      if (info) {
        return _.setIn(["results", "info", "name"], payload.name).setIn(
          ["results", "conversations"],
          _newList,
        );
      }
      return _.setIn(["results", "conversations"], _newList);
    }
    case CHANGE_NAME_EDITABLE: {
      return _.setIn(["config", "isEditable"], payload.status);
    }
    case GET_REFERENCE_DONE: {
      const data = Immutable.fromJS(payload.doc);
      const sources = data.map((item) =>
        Immutable.Map({
          ...item.toJS(),
          type:
            item.getIn(["metadata", "file_type"]) === "url" ? "url" : "file",
          id: item.get("id"),
          path: item.getIn(["metadata", "file_name"]),
        }),
      );
      return _.set("sources", sources).setIn(
        ["config", "selectedSources"],
        Immutable.List(),
      );
    }
    case SEL_SOURCES: {
      return _.setIn(["config", "selectedSources"], payload.sources);
    }
    case SEARCH_SOURCES: {
      return _.setIn(["results", "reference"], Immutable.fromJS(payload.doc));
    }
    case CLEAR_CONVERSATION: {
      return _.setIn(["results", "info"], null)
        .setIn(["results", "reference"], null)
        .setIn(["results", "logs"], null)
        .set("sources", [])
        .setIn(["config", "selectedSources"], Immutable.List())
        .set("is_search_internet", false)
        .set("files", []);
    }
    case USER_LOGOUT: {
      return INIT_STATE;
    }
    case RESET_CONVERSATION: {
      return _.set("isLoading", true);
    }
    case RESET_CONVERSATION_DONE: {
      const existingSources = _.getIn(["results", "info", "sources"]);
      return _.set("isLoading", false).set("sources", existingSources);
    }
    case SET_LOG_INDEX: {
      return _.setIn(["config", "logIndex"], payload.index);
    }
    case LIKE_CONVERSATION_DONE: {
      return _.updateIn(["results", "logs"], (logs) =>
        logs.map((log) =>
          log.get("id") === payload.id ? log.set("reactions", ["like"]) : log,
        ),
      );
    }
    case DISLIKE_CONVERSATION_DONE: {
      return _.updateIn(["results", "logs"], (logs) =>
        logs.map((log) =>
          log.get("id") === payload.id
            ? log.set("reactions", ["dislike"])
            : log,
        ),
      );
    }
    case CNG_SEARCH_INTERNET: {
      return _.set("is_search_internet", payload.status);
    }
    case ADD_FILES: {
      return _.set("files", _.getIn(["files"]).concat(payload.files));
    }
    case REMOVE_FILE: {
      return _.set(
        "files",
        _.getIn(["files"]).filter((file) => file.filename !== payload.filename),
      );
    }
    default:
      return _;
  }
};

export default conversationReducer;
