import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import { errorAlert } from "app/redux/actions/alert";
import { successAlert } from "app/redux/actions/alert";
import { uploadFile } from "app/redux/actions/files";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import RecordModal from "../Modal/RecordModal";

const UploadMenu = ({ supportType = [], multiple, onFinish, disabled }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const [isRecordOpen, setIsRecordOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadAudio = async (e) => {
    const files = Array.from(e);
    const isValidFile = files.every((file) => file.type.includes("audio"));

    if (!isValidFile) {
      dispatch(errorAlert("不支援的檔案格式"));
      return;
    }

    try {
      const res = await dispatch(uploadFile(files));
      onFinish(res);
      dispatch(successAlert("添加成功"));
    } catch (error) {
      dispatch(errorAlert("添加失敗"));
    }
  };

  const handleUploadImage = async (e) => {
    const files = Array.from(e.target.files);

    if (supportType.length > 0) {
      const isValidFile = multiple
        ? files.every((file) => file.type === "image/*")
        : files[0].type === "image/*";

      if (!isValidFile) {
        dispatch(errorAlert("不支援的檔案格式"));
        if (fileInputRef.current) {
          fileInputRef.current.files = null;
        }
        return;
      }
    }

    try {
      const res = await dispatch(uploadFile(files));
      onFinish(res);
      dispatch(successAlert("添加成功"));
    } catch (error) {
      dispatch(errorAlert("添加失敗"));
    }
  };

  const handleRecordOpen = () => {
    setIsRecordOpen(true);
    setAnchorEl(null);
  };

  const handleRecordClose = () => {
    setIsRecordOpen(false);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <IconButton disabled={disabled} onClick={handleOpen} sx={{ p: 0 }}>
        <ControlPointIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem component="label">
          新增圖片
          <VisuallyHiddenInput
            ref={fileInputRef}
            type="file"
            onChange={handleUploadImage}
          />
        </MenuItem>
        <MenuItem onClick={handleRecordOpen}>新增錄音</MenuItem>
      </Menu>
      <RecordModal
        open={isRecordOpen}
        onClose={handleRecordClose}
        onUpload={handleUploadAudio}
      />
    </>
  );
};

export default UploadMenu;

UploadMenu.propTypes = {
  supportType: PropTypes.array,
  multiple: PropTypes.bool,
  onFinish: PropTypes.func,
  disabled: PropTypes.bool,
};
