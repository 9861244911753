import http from "app/services";
import moment from "moment";
import * as uuid from "uuid";

import {
  ADD_FILES,
  CHANGE_NAME_EDITABLE,
  CLEAR_CONVERSATION,
  CNG_SEARCH_INTERNET,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_DONE,
  DEL_CONVERSATION,
  DEL_CONVERSATION_DONE,
  DISLIKE_CONVERSATION,
  DISLIKE_CONVERSATION_DONE,
  FCH_CONVERSATION,
  FCH_CONVERSATION_DONE,
  FCH_CONVERSATIONS,
  FCH_CONVERSATIONS_DONE,
  FCH_LOGS,
  FCH_LOGS_DONE,
  GET_REFERENCE,
  GET_REFERENCE_DONE,
  LIKE_CONVERSATION,
  LIKE_CONVERSATION_DONE,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGE_DONE,
  RECEIVE_STREAM_MESSAGE_DONE,
  REMOVE_FILE,
  RESET_CONVERSATION,
  RESET_CONVERSATION_DONE,
  SEL_SOURCES,
  SEND_MESSAGE,
  SEND_MESSAGE_DONE,
  SEND_STREAM_MESSAGE,
  SEND_STREAM_MESSAGE_DONE,
  SET_LOG_INDEX,
  STOP_BATCH_MESSAGE,
  STOP_STREAM_MESSAGE,
  UPD_NAME,
  UPD_NAME_DONE,
} from "../types/conversation";
import { statusHandler, successAlert } from "./alert";
import { fchKnowledgebase } from "./knowledgebase";

export const fchConversationList = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_CONVERSATIONS });
    const res = await http.get(`/api/v1/users/${id}/conversations`);
    if (res.response && res.response.status === 401) {
      return { code: res.response.status, message: "伺服器錯誤" };
    }
    dispatch({ type: FCH_CONVERSATIONS_DONE, payload: { doc: res.data } });
    return res.data;
  };
};

export const fchConversation = (id) => {
  return async (dispatch) => {
    dispatch({ type: FCH_CONVERSATION });
    const res = await http.get(`/api/v1/conversations/${id}`);
    if (!res.data) {
      return window.location.replace("/404");
    }

    if (res.response && res.response.status >= 400) {
      return window.location.replace("/404");
    }
    dispatch(fchConversationLogs(id));
    const kId = res.data.knowledge_base_id;
    if (kId) dispatch(fchKnowledgebase(kId));
    dispatch({ type: FCH_CONVERSATION_DONE, payload: { doc: res.data } });
  };
};

export const fchConversationLogs = (cId) => {
  return async (dispatch) => {
    dispatch({ type: FCH_LOGS });
    const res = await http.get(`/api/v1/conversations/${cId}/logs`);
    dispatch({ type: FCH_LOGS_DONE, payload: { doc: res.data } });
    return res.data;
  };
};

export const createConversation = (uId, formData) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_CONVERSATION });
    const res = await http.post(`/api/v1/users/${uId}/conversations`, formData);
    const id = res.data.payload.id;
    const doc = {
      ...formData,
      id,
      user_id: uId,
      is_deleted: false,
      created_at: moment().unix(),
    };
    dispatch({
      type: CREATE_CONVERSATION_DONE,
      payload: { doc },
    });
    dispatch(successAlert("對話已建立"));
    return id;
  };
};

export const sendMessage = (cId, message, sources = []) => {
  return async (dispatch) => {
    dispatch({ type: SEND_MESSAGE });
    const receivedUUID = uuid.v4();
    const sendMsg = {
      id: uuid.v4(),
      conversation_id: cId,
      message,
      created_at: moment().unix(),
      role: "user",
    };
    const receivedMsg = {
      id: receivedUUID,
      conversation_id: cId,
      message: "",
      created_at: "",
      role: "assistant",
    };

    const msg = [sendMsg, receivedMsg];
    dispatch({ type: SEND_MESSAGE_DONE, payload: { doc: msg } });

    const res = await http.post(`/api/v1/conversations/${cId}/logs`, {
      message,
      sources,
    });

    let resMsg = res.data;

    if (res.__CANCEL__) return;

    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      resMsg = "伺服器錯誤";
    }

    const doc = {
      ...receivedMsg,
      message: resMsg,
    };
    dispatch({ type: RECEIVE_MESSAGE, payload: { doc } });
    dispatch({ type: RECEIVE_MESSAGE_DONE, payload: { id: receivedUUID } });
  };
};

export const sendStreamMessage = (cId, doc, signalKey) => {
  return async (dispatch) => {
    dispatch({ type: SEND_STREAM_MESSAGE });
    const files = doc.files.map((file) => {
      return {
        ...file,
        type: file.filename.endsWith(".mp3") ? "audio" : "image",
      };
    });
    const receivedUUID = uuid.v4();
    const sendMsg = {
      id: uuid.v4(),
      conversation_id: cId,
      message: doc.message,
      created_at: moment().unix(),
      role: "user",
      files,
    };
    const receivedMsg = {
      id: receivedUUID,
      conversation_id: cId,
      message: "",
      created_at: "",
      role: "assistant",
    };

    const msg = [sendMsg, receivedMsg];
    dispatch({ type: SEND_STREAM_MESSAGE_DONE, payload: { doc: msg } });

    let updatedId;

    const parseSSEChunk = (chunk) => {
      const eventBlocks = chunk.split("\n\n").filter((block) => block.trim());

      return eventBlocks
        .map((block) => {
          const eventMatch = block.match(/event: (.+)/);
          const dataMatch = block.match(/data: (.+)/);

          if (!eventMatch || !dataMatch) {
            return null;
          }

          try {
            return {
              event: eventMatch[1],
              data: JSON.parse(dataMatch[1]),
            };
          } catch (error) {
            console.error("Error parsing SSE data:", error);
            return null;
          }
        })
        .filter(Boolean); // 移除空值
    };

    const handleStreamData = (chunk) => {
      const events = parseSSEChunk(chunk);

      events.forEach(({ event, data }) => {
        const doc = {
          ...receivedMsg,
        };
        if (event === "message") {
          doc.message = data.message;
          dispatch({ type: RECEIVE_MESSAGE, payload: { doc } });
        }
        if (event === "reference") {
          dispatch({ type: GET_REFERENCE });
          dispatch({
            type: GET_REFERENCE_DONE,
            payload: { doc: data.references },
          });
          updatedId = data.log.id;
        }
        if (event === "text_to_sql") {
          doc.chartjs = data.chartjs;
          doc.sql = data.sql;
          dispatch({ type: RECEIVE_MESSAGE, payload: { doc } });
        }
      });
    };

    await http.stream(
      `/sse/v1/conversations/${cId}/logs`,
      {
        ...doc,
      },
      handleStreamData,
      signalKey,
    );

    dispatch({
      type: RECEIVE_STREAM_MESSAGE_DONE,
      payload: { id: receivedUUID, updatedId },
    });

    dispatch({ type: STOP_STREAM_MESSAGE });
  };
};

export const stopStreamMessage = (signalKey) => {
  return async (dispatch) => {
    await http.stopStream(signalKey);
    dispatch({ type: STOP_STREAM_MESSAGE });
  };
};

export const delConversation = (cId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_CONVERSATION });
    const res = await http.delete(`/api/v1/conversations/${cId}`);
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }
    dispatch({ type: DEL_CONVERSATION_DONE, payload: { id: cId } });
    dispatch(successAlert("對話已刪除"));
    return true;
  };
};

export const clearConversation = () => {
  return { type: CLEAR_CONVERSATION };
};

export const updConversationName = (cId, name) => {
  return async (dispatch) => {
    dispatch({ type: UPD_NAME });
    const res = await http.patch(`/api/v1/conversations/${cId}`, { name });
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: UPD_NAME_DONE, payload: { id: cId, name } });
    dispatch(successAlert("對話名稱已更新"));
  };
};

export const changeNameEditable = (status) => {
  return { type: CHANGE_NAME_EDITABLE, payload: { status } };
};

export const resetConversation = (id) => {
  return async (dispatch) => {
    dispatch({ type: RESET_CONVERSATION });
    const res = await http.post(`/api/v1/conversations/${id}/logs/_reset`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    const timeout = setTimeout(() => {
      dispatch(successAlert("RAGi對話已重置！"));
      dispatch(fchConversationLogs(id));
      dispatch({ type: RESET_CONVERSATION_DONE });
    }, 1000);
    return () => clearTimeout(timeout);
  };
};

export const stopBatchMessage = () => {
  return async (dispatch) => {
    await http.cancelPost();
    dispatch({ type: STOP_BATCH_MESSAGE });
  };
};

export const setLogIndex = (index) => {
  return { type: SET_LOG_INDEX, payload: { index } };
};

export const getReference = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_REFERENCE });
    const res = await http.get(`/api/v1/logs/${id}/references`);
    dispatch({ type: GET_REFERENCE_DONE, payload: { doc: res.data } });
    return res.data;
  };
};

export const selectSources = (sources) => {
  return { type: SEL_SOURCES, payload: { sources } };
};

export const likeConversation = (lId) => {
  return async (dispatch) => {
    dispatch({ type: LIKE_CONVERSATION });
    const res = await http.post(`/api/v1/logs/${lId}/reactions`, {
      reaction: "like",
    });
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: LIKE_CONVERSATION_DONE, payload: { id: lId } });
  };
};

export const dislikeConversation = (lId) => {
  return async (dispatch) => {
    dispatch({ type: DISLIKE_CONVERSATION });
    const res = await http.post(`/api/v1/logs/${lId}/reactions`, {
      reaction: "dislike",
    });
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DISLIKE_CONVERSATION_DONE, payload: { id: lId } });
  };
};

export const cngSearchInternet = (status) => {
  return { type: CNG_SEARCH_INTERNET, payload: { status } };
};

export const addFiles = (files) => {
  return { type: ADD_FILES, payload: { files } };
};

export const removeFile = (filename) => {
  return { type: REMOVE_FILE, payload: { filename } };
};
