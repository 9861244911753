import Div from "@jumbo/shared/Div";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

const ImageDisplay = ({ path, onRemove, size = "normal", filename }) => {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const handleOpenImage = () => {
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
  };

  const SIZE = {
    normal: {
      width: 100,
      height: 100,
    },
    small: {
      width: 50,
      height: 50,
    },
  };
  return (
    <>
      <Div
        sx={{
          position: "relative",
          cursor: "pointer",
          border: "1px solid #c0c0c0",
          borderRadius: 1,
          p: 1,
          pb: 0.4,
        }}
        onClick={handleOpenImage}
      >
        <img
          src={path}
          alt={filename}
          style={{
            width: SIZE[size].width,
            height: SIZE[size].height,
            objectFit: "cover",
          }}
        />
        {onRemove && (
          <IconButton
            onClick={(e) => onRemove(e, filename)}
            sx={{ position: "absolute", top: -20, right: -20 }}
          >
            <CancelOutlinedIcon
              fontSize="small"
              sx={{ color: "#c0c0c0", bgcolor: "#fff", borderRadius: 99 }}
            />
          </IconButton>
        )}
      </Div>
      <Dialog
        open={isImageOpen}
        onClose={handleCloseImage}
        maxWidth={false} // 不設最大寬度，讓它根據內容自動調整
        fullWidth={false} // 讓 Dialog 根據內容寬度自適應
        PaperProps={{
          sx: {
            minWidth: "auto", // 防止有最小寬度限制
            minHeight: "auto", // 防止有最小高度限制
          },
        }}
      >
        <DialogContent>
          <img src={path} alt={path} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageDisplay;

ImageDisplay.propTypes = {
  path: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  size: PropTypes.string,
  filename: PropTypes.string,
};
