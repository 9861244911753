import Div from "@jumbo/shared/Div";
import { Divider, Modal, Stack, Typography } from "@mui/material";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";

const FileDetailModal = ({ file, isOpen, onClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  const urlConvert = (path) => {
    if (!path) return "";
    if (path.startsWith("http")) return path;
    return path.split("/").pop();
  };

  if (!isOpen) return null;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Div sx={style}>
        <Stack flexDirection="row" color="orange" gap={4} p={2}>
          <Typography
            variant="h5"
            m={0}
            width="100%"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "80%",
            }}
          >
            {file.getIn(["metadata", "file_name"])
              ? file.getIn(["metadata", "file_name"])
              : urlConvert(file.getIn(["metadata", "file_path"]))}
          </Typography>
          {file.getIn(["metadata", "pages"]) && (
            <Typography variant="h5" m={0}>
              {`p.${file.getIn(["metadata", "page_number"])}/p.${file.getIn(["metadata", "pages"])}`}
            </Typography>
          )}
        </Stack>
        <Divider />
        <Div sx={{ p: 2 }}>{file.get("text") || "No content"}</Div>
      </Div>
    </Modal>
  );
};

export default FileDetailModal;

FileDetailModal.propTypes = {
  file: PropTypes.instanceOf(Immutable.Map).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
