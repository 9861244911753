import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import AudioPlayer from "./audioPlayer";
import ImageDisplay from "./imageDisplay";

const FileSection = ({ files, removeFile }) => {
  if (files.length === 0) return null;

  const handleRemoveFile = (e, filename) => {
    e.stopPropagation();
    removeFile(filename);
  };

  const handleFormatPath = (path) => {
    const origin = window.location.origin;
    const formattedPath = path.replace("/tmp", "") + "?namespace=files";
    return `${origin}${formattedPath}`;
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={2}
      flexWrap="wrap"
      sx={{
        top: -38,
        width: "100%",
        backgroundColor: "rgba(0,0,0,0)",
        p: 1.5,
        maxHeight: 280,
        overflowY: "auto",
      }}
    >
      {files.map((file) => {
        const path = handleFormatPath(file.path);

        if (file.filename.endsWith(".mp3")) {
          return (
            <AudioPlayer
              key={file.id}
              path={path}
              filename={file.filename}
              onRemove={handleRemoveFile}
            />
          );
        }

        return (
          <ImageDisplay
            key={file.id}
            path={path}
            filename={file.filename}
            onRemove={handleRemoveFile}
          />
        );
      })}
    </Stack>
  );
};

export default FileSection;

FileSection.propTypes = {
  files: PropTypes.array.isRequired,
  removeFile: PropTypes.func.isRequired,
};
