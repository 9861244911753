import Div from "@jumbo/shared/Div";
import { SearchOutlined } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LinkIcon from "@mui/icons-material/Link";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Checkbox,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { selectSources } from "app/redux/actions/conversation";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import FileDetailModal from "../Modal/FileDetailModal";

const urlConvert = (path) => {
  if (!path) return "";
  if (path.startsWith("http")) return path;
  return path.split("/").pop();
};

function RetrievalFile({ sources, selectedSources }) {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleSelectSources = (source, checked) => {
    if (checked) {
      dispatch(selectSources(selectedSources.push(source)));
    } else {
      dispatch(
        selectSources(
          selectedSources.filter((s) => s.get("id") !== source.get("id")),
        ),
      );
    }
  };

  const handleDownload = (source) => {
    const origin = window.location.origin;
    const path = source.getIn(["metadata", "file_path"]).replace("/tmp", "");
    const url = `${origin}${path}?namespace=files`;

    const link = document.createElement("a");
    link.href = url;
    link.download = source.getIn(["metadata", "file_name"]);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredSources = useMemo(() => {
    return sources.filter((source) =>
      (source.get("path") || source.get("url") || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    );
  }, [sources, searchTerm]);

  const handleOpen = (file) => {
    setFile(file);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setFile(null);
  };

  return (
    <>
      <Card sx={{ p: 3, height: "calc(100vh - 238px)" }}>
        <Stack
          direction={{ xs: "column", md: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack flexDirection="row">
            <Typography variant="h3" sx={{ mr: 0.3, mb: 0 }}>
              檔案檢索
            </Typography>
            <Tooltip title="請選擇檔案做為對話參考，若未選擇檔案會根據整個知識庫檔案做為對話依據">
              <ErrorOutlineIcon
                sx={{
                  fontSize: 16,
                  color: "#c0c0c0",
                }}
              />
            </Tooltip>
          </Stack>
          <TextField
            size="small"
            placeholder="檔案名稱"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{ endAdornment: <SearchOutlined /> }}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Div
          sx={{
            height: "64%",
            overflow: "auto",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {filteredSources.size > 0 &&
            filteredSources.map((source, index) => (
              <Div
                key={source.get("id")}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: filteredSources.size - 1 === index ? 0 : 1,
                  alignItems: "flex-start",
                }}
              >
                <Checkbox
                  sx={{ mr: 1, p: 0 }}
                  size="small"
                  checked={selectedSources.includes(source)}
                  onChange={(e, checked) =>
                    handleSelectSources(source, checked)
                  }
                />
                <TitleWithTooltip
                  showText
                  showIcon
                  source={source}
                  onOpen={handleOpen}
                  onDownload={handleDownload}
                />
              </Div>
            ))}
          {filteredSources.size === 0 && (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="100%"
              spacing={1}
            >
              <InsertDriveFileIcon sx={{ color: "#ccc", fontSize: 36 }} />
              <Typography variant="body1" color="#ccc">
                查無參考資料
              </Typography>
            </Stack>
          )}
        </Div>
        <Divider sx={{ my: 2 }} />
        <Div sx={{ height: "14%" }}>
          <Typography variant="h5">
            已選取檔案 {selectedSources.size}
          </Typography>
          <Div
            sx={{
              height: "100%",
              overflow: "auto",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {selectedSources.map((source, index) => (
              <Stack
                key={source.get("id")}
                mb={selectedSources.size - 1 === index ? 0 : 1}
              >
                <TitleWithTooltip source={source} />
              </Stack>
            ))}
          </Div>
        </Div>
      </Card>
      <FileDetailModal file={file} isOpen={isOpen} onClose={handleClose} />
    </>
  );
}

export default RetrievalFile;

RetrievalFile.propTypes = {
  sources: PropTypes.instanceOf(Immutable.List),
  selectedSources: PropTypes.instanceOf(Immutable.List),
};

const typeDict = {
  url: {
    name: "網址",
    icon: <LinkIcon sx={{ fontSize: 20 }} />,
  },
  file: {
    name: "檔案",
    icon: <InsertDriveFileIcon sx={{ fontSize: 20 }} />,
  },
};

const TitleWithTooltip = ({
  source,
  onOpen,
  onDownload,
  showIcon = false,
  showText = false,
}) => {
  const titleRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  const title = urlConvert(source.get("path"));

  useEffect(() => {
    const element = titleRef.current;
    if (element) {
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      tempSpan.innerHTML = title;
      document.body.appendChild(tempSpan);

      const actualWidth = tempSpan.offsetWidth;
      const availableWidth = element.offsetWidth;

      document.body.removeChild(tempSpan);

      setIsOverflowed(actualWidth > availableWidth);
    }
  }, [title]);

  const TitleComponent = (
    <Stack direction="row" alignItems="center" width="100%" mb={0.5}>
      <Stack direction="row" alignItems="center" width="100%" gap={0.5}>
        {typeDict[source.get("type")].icon}
        <Typography
          ref={titleRef}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            wordBreak: "break-all",
            width: "90%",
          }}
        >
          {source.get("type") === "url" && (
            <Link
              href={source.get("path")}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "inherit",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                wordBreak: "break-all",
                width: "86%",
              }}
            >
              {title}
            </Link>
          )}
          {source.get("type") !== "url" && <Typography>{title}</Typography>}
        </Typography>
      </Stack>
      {showIcon && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          position="relative"
          top={2}
        >
          {source.get("metadata") && (
            <IconButton sx={{ p: 0 }} onClick={() => onOpen(source)}>
              <VisibilityIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
          {source.get("type") === "file" && (
            <IconButton sx={{ p: 0 }} onClick={() => onDownload(source)}>
              <DownloadIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Stack>
      )}
    </Stack>
  );

  return (
    <Div sx={{ width: "100%" }}>
      {isOverflowed ? (
        <Tooltip
          title={title}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-50, -10],
                  },
                },
              ],
            },
          }}
        >
          {TitleComponent}
        </Tooltip>
      ) : (
        TitleComponent
      )}
      {showText && source.get("text") && (
        <Typography
          color="gray"
          fontSize={14}
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          {source.get("text")}
        </Typography>
      )}
    </Div>
  );
};

TitleWithTooltip.propTypes = {
  source: PropTypes.instanceOf(Immutable.Map).isRequired,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  onOpen: PropTypes.func,
  onDownload: PropTypes.func,
};
