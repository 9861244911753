import "react-h5-audio-player/lib/styles.css";

import React from "react";
import H5AudioPlayer from "react-h5-audio-player";

const AudioPlayer = ({ path }) => {
  return <H5AudioPlayer src={path} />;
};

export default AudioPlayer;
