import {
  Close,
  Download,
  Mic as MicIcon,
  Upload as UploadIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import AudioPlayer from "../AudioPlayer";

const StyledModal = styled(Modal)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  width: "400px",
  maxWidth: "90vw",
}));

const VisualizerContainer = styled(Box)(() => ({
  width: "200px",
  height: "200px",
  margin: "20px auto",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const WaveCircle = styled("div")(({ theme, size, volume = 0 }) => ({
  position: "absolute",
  width: size,
  height: size,
  borderRadius: "50%",
  border: `2px solid ${theme.palette.primary.main}`,
  opacity: volume > 0.05 ? 0.8 : 0,
  animation: volume > 0.05 ? "waveAnimation 1s infinite ease-out" : "none",
  "@keyframes waveAnimation": {
    "0%": {
      transform: `scale(${1 + volume * 1.5})`,
      opacity: 0.8,
    },
    "100%": {
      transform: `scale(${2 + volume * 2})`,
      opacity: 0,
    },
  },
}));

const AudioRecorderModal = ({ open, onClose, onUpload }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [volume, setVolume] = useState(0);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const audioFileRef = useRef(null);

  const createFileFromBlob = (blob, fileName) => {
    const timestamp = new Date().getTime();
    return new File([blob], fileName || `recording-${timestamp}.mp3`, {
      type: blob.type,
    });
  };

  const processAudioData = () => {
    if (analyserRef.current) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);

      const average =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
      const normalizedVolume = Math.pow(average / 128, 1.8);
      setVolume(Math.min(normalizedVolume * 1.5, 1));

      animationFrameRef.current = requestAnimationFrame(processAudioData);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();

      const source = audioContextRef.current.createMediaStreamSource(stream);
      const analyser = audioContextRef.current.createAnalyser();
      analyser.fftSize = 256;
      source.connect(analyser);
      analyserRef.current = analyser;

      const mimeTypes = [
        "audio/webm;codecs=opus",
        "audio/mp4",
        "audio/mpeg",
        "audio/webm",
      ];

      let selectedMimeType;
      for (const mimeType of mimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          selectedMimeType = mimeType;
          break;
        }
      }

      if (!selectedMimeType) {
        throw new Error("No supported MIME type found");
      }

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: selectedMimeType,
      });

      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: mediaRecorder.mimeType || "audio/webm",
        });
        const url = URL.createObjectURL(audioBlob);
        const audioFile = createFileFromBlob(audioBlob);
        audioFileRef.current = audioFile;
        setAudioBlob(audioBlob);
        setAudioUrl(url);
      };

      mediaRecorder.start(250);
      setIsRecording(true);
      processAudioData();
    } catch (error) {
      console.error("Error in recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current.close();
      }
      setIsRecording(false);
      setVolume(0);
    }
  };

  const handleDownload = () => {
    if (audioFileRef.current) {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(audioFileRef.current);
      a.download = audioFileRef.current.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    }
  };

  const cleanupResources = () => {
    if (audioUrl) {
      URL.revokeObjectURL(audioUrl);
    }
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close();
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setIsRecording(false);
    setVolume(0);
  };

  const handleClose = () => {
    cleanupResources();
    setAudioBlob(null);
    setAudioUrl(null);
    audioFileRef.current = null;
    onClose();
  };

  const handleUpload = () => {
    if (audioFileRef.current && onUpload) {
      onUpload([audioFileRef.current]);
      handleClose();
    }
  };

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, []);

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="audio-recorder-modal"
    >
      <ModalContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <Typography variant="h4" sx={{ m: 0 }}>
            錄音
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </Box>

        {!audioBlob && (
          <VisualizerContainer>
            <MicIcon
              sx={{
                fontSize: 48,
                color: isRecording ? "error.main" : "primary.main",
                transform: `scale(${1 + volume * 0.3})`,
                transition: "transform 0.1s ease-in-out",
                position: "relative",
                zIndex: 2,
              }}
            />
            {isRecording && (
              <>
                <WaveCircle size="60px" delay="0s" volume={volume} />
                <WaveCircle size="60px" delay="0.5s" volume={volume * 0.8} />
                <WaveCircle size="60px" delay="1s" volume={volume * 0.6} />
              </>
            )}
          </VisualizerContainer>
        )}

        <Box
          display="flex"
          justifyContent="center"
          gap={2}
          flexDirection="column"
          alignItems="center"
        >
          {!audioBlob ? (
            <Button
              variant="contained"
              color={isRecording ? "error" : "primary"}
              onClick={isRecording ? stopRecording : startRecording}
            >
              {isRecording ? "停止錄音" : "開始錄音"}
            </Button>
          ) : (
            <>
              <AudioPlayer
                path={audioUrl}
                filename={audioFileRef.current.name}
              />
              <Box
                display="flex"
                justifyContent="center"
                gap={2}
                sx={{ mt: 2 }}
              >
                <Button
                  variant="outlined"
                  startIcon={<Download />}
                  onClick={handleDownload}
                >
                  下載
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAudioBlob(null);
                    setAudioUrl(null);
                    audioFileRef.current = null;
                  }}
                >
                  重新錄音
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<UploadIcon />}
                  onClick={handleUpload}
                >
                  添加到對話
                </Button>
              </Box>
            </>
          )}
        </Box>
      </ModalContent>
    </StyledModal>
  );
};

export default AudioRecorderModal;

AudioRecorderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};
